<nav class="navbar navbar-expand-lg nav-crev" [class.nav-scroll]="isSticky">
    <div class="container">
        <a class="navbar-brand icon-img-100" routerLink = "/" style="margin-right: 200px;">
            <img [src]="logo" alt="Logo" class="logo" (click)="isCollapsed = false">
        </a>
        <button class="navbar-toggler ms-auto" type="button" (click)="isCollapsed = !isCollapsed" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
            <span class="icon-bar"><i class="icofont icofont-navigation-menu"></i></span>
        </button>
        <div class="collapse navbar-collapse" [ngClass]="{'collapse': isCollapsed, 'navbar-collapse': true}" id="navbarNavAltMarkup">
            <ul class="navbar-nav navbar-center xs-mt-20">
                <li class="nav-item" >
                    <a class="nav-link" (click)="scrollToSection('home')" [class.active]="isActive('home')">Inicio</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" (click)="scrollToSection('features')" [class.active]="isActive('features')">Caracteristicas</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" (click)="scrollToSection('servicios')" [class.active]="isActive('servicios')">Servicios</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" (click)="scrollToSection('nosotros')" [class.active]="isActive('nosotros')">Nosotros</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" (click)="scrollToSection('portfolio')" [class.active]="isActive('portfolio')">Soluciones</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" (click)="scrollToSection('bolsadetrabajo')" [class.active]="isActive('bolsadetrabajo')">Bolsa de trabajo</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" (click)="scrollToSection('contact')" [class.active]="isActive('contact')">Contacto</a>
                </li>
            </ul>
            
        </div>
        
    </div>
</nav>