import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeSevenComponent } from './components/pages/home-seven/home-seven.component';
import { ErrorComponent } from './components/pages/error/error.component';

const routes: Routes = [
  {path: '', component: HomeSevenComponent},
  {path: 'error', component: ErrorComponent},
  {path: '**', redirectTo: 'error'} // Redirigir a la página de error para cualquier otra ruta
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
