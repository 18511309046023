<footer class="footer">
    <div class="footer-copyright">
      <div class="container">
        <div class="row">
          <div class="col-md-6 col-xs-12">
            <div class="copy-right text-left">© {{ currentYear }} K2W IT SOLUTIONS. All rights reserved</div>
          </div>
          
        </div>
      </div>
    </div>
  </footer>