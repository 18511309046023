import { Component, HostListener } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';  // Importa el Router

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.css'
})
export class NavbarComponent {
  isSticky: boolean = false;
  logo: string = 'assets/images/logo-white.png';
  isCollapsed = true;
  currentSection: string = '';

  sections = ['home', 'features', 'servicios', 'nosotros', 'portfolio', 'bolsadetrabajo', 'contact'];
  
  constructor(private router: Router) {
    // Escucha los cambios de ruta
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.currentSection = event.urlAfterRedirects;
      }
    });
  }
    
  scrollToSection(sectionId: string) {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    if (scrollTop > 100) {
      this.isSticky = true;
      this.logo = 'assets/images/logo-black.png';
    } else {
      this.isSticky = false;
      this.logo = 'assets/images/logo-white.png';
    }

    this.updateCurrentSection();
  }

  private updateCurrentSection() {
    let currentSectionId = '';
    this.sections.forEach(sectionId => {
      const section = document.getElementById(sectionId);
      if (section) {
        const rect = section.getBoundingClientRect();
        if (rect.top <= 150 && rect.bottom >= 150) {
          currentSectionId = sectionId;
        }
      }
    });
    this.currentSection = currentSectionId;
  }

  isActive(sectionId: string): boolean {
    return this.currentSection === sectionId;
  }

}
